import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Prefooter from './components/footer/Prefooter';
import Home from './screens/Home';
// eslint-disable-next-line
import Items from './components/items/Items';
// eslint-disable-next-line
import About from './screens/About';
// eslint-disable-next-line
import Book from './screens/Book';
// eslint-disable-next-line
import Documentary from './screens/Documentary';
// eslint-disable-next-line
import Evolution from './screens/Evolution';
// eslint-disable-next-line
import Exibition from './screens/Exibition';
// eslint-disable-next-line
import Offer from './screens/Offer';
// eslint-disable-next-line
import Proposal from './screens/Proposal';
// eslint-disable-next-line
import Tour from './screens/Tour';
// eslint-disable-next-line
import Who from './screens/Who';

function App() {
    return (
        <>
            <Navbar />
            <Home />
            {/* <About />
            <Who />
            <Tour />
            <Evolution />
            <Exibition />
            <Documentary />
            <Book />
            <Items />
            <Proposal />
            <Offer />*/}
            <Prefooter /> 
            <Footer />
        </>
    );
}

export default App;
